<template>
    <div class="btn-building mb-3 d-flex text-center w-100">
        <div class="bg-secondary text-light w-25 d-flex justify-content-center align-items-center">
            {{ building.id }}
        </div>
        <div class="p-2 bld-name-section w-75 fw-bold">
            <div class="text-nowrap mx-2">
                {{ building.name }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {Options, prop} from "vue-class-component";
import Vue from "@/extensions/Vue";
import BuildingData from "@/http/data/building-data";

class Props {
    building = prop({
        required: true,
        type: Object
    });
}

@Options({
    name: "BuildingButton"
})
export default class BuildingButton extends Vue.with(Props) {
    declare building: BuildingData;
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.btn-building {
    cursor: pointer;
}

.bld-name-section {
    border-top: solid 1px $secondary;
    border-right: solid 1px $secondary;
    border-bottom: solid 1px $secondary;
}

.text-nowrap {
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>