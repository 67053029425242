
import {Options, prop} from "vue-class-component";
import Vue from "@/extensions/Vue";
import BuildingData from "@/http/data/building-data";

class Props {
    building = prop({
        required: true,
        type: Object
    });
}

@Options({
    name: "BuildingButton"
})
export default class BuildingButton extends Vue.with(Props) {
    declare building: BuildingData;
}
