<template>
    <default-layout>
        <div class="container mt-4 d-flex flex-column align-items-center">
            <building-button v-for="building in buildings"
                             :key="building.id"
                             :building="building"
                             @click="onBuildingClick(building)"/>
        </div>
    </default-layout>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import {buildingContext} from "@/store";
import BuildingButton from "@/components/controls/BuildingButton.vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import BuildingState from "@/store/modules/building/building-state";
import BuildingData from "@/http/data/building-data";

@Options({
    name: "Buildings",
    components: {
        DefaultLayout,
        BuildingButton
    }
})
export default class Buildings extends Vue {
    selectBuilding = buildingContext.actions.selectBuilding;

    get buildings(): BuildingState["buildings"] {
        return buildingContext.state.buildings;
    }

    async onBuildingClick(building: BuildingData): Promise<void> {
        if (!building.id) return;

        await this.selectBuilding(building.id);

        await this.$router.push({name: "Dashboard"});
    }
}
</script>

<style scoped>

</style>